<template>
  <v-dialog v-model="dialog" persistent width="500">
    <v-form ref="formData" v-model="valid" onSubmit="return false;">
      <v-card>
        <div class="pa-3">
          <div class="d-flex align-center justify-space-between">
            <div class="text-h6">
              {{ type === TYPE_CREATE ? 'Create your Organization' : 'Update Organization' }}
            </div>
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-divider class="mt-2" />
        </div>
        <div class="pa-3">
          <v-text-field
            v-model="form.name"
            :rules="[rules.feildRequired]"
            autofocus
            dense
            label="Name"
            outlined
            placeholder="Your Organiztion name"
            prepend-inner-icon="icon-edit_square"
          ></v-text-field>

          <v-text-field
            v-model="form.email"
            :rules="[rules.emailRules]"
            autofocus
            class="mt-2"
            dense
            label="Email"
            outlined
            placeholder="Your Organiztion Email"
            prepend-inner-icon="icon-message"
          ></v-text-field>
          <v-text-field
            v-model="form.phone"
            autofocus
            class="mt-1"
            dense
            label="Phone"
            outlined
            placeholder="Your Organiztion Phone"
            prepend-inner-icon="icon-call"
          ></v-text-field>

          <v-textarea
            v-model="form.address"
            autofocus
            class="mt-1"
            dense
            outlined
            placeholder="Address"
            prepend-inner-icon="icon-location"
            rows="2"
          ></v-textarea>
        </div>

        <v-card-actions>
          <v-layout>
            <v-spacer />
            <v-btn :disabled="loading" class="mx-2" color="secondary" rounded type="reset" @click="dialog = false">
              Close
            </v-btn>
            <v-btn :loading="loading" color="primary" rounded type="submit" @click="submitData">
              Submit
            </v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { createOrganization, updateOrganization } from '@/api/organization'
import { checkToken } from '@/api/auth-api'
import Store from '@/store'

const TYPE_CREATE = 'create'
const TYPE_UPDATE = 'update'

export default {
  props: {
    roles: { type: Array, default: () => [] },
  },
  data: () => ({
    TYPE_CREATE,
    TYPE_UPDATE,
    dialog: false,
    loading: false,
    valid: false,
    rules: {
      feildRequired: value => !!value || 'Feild is required',
      emailRules: v => /.+@.+/.test(v) || 'E-mail must be valid',
    },
    type: TYPE_CREATE,
    form: {},
  }),
  methods: {
    showCreate() {
      this.type = TYPE_CREATE
      this.form = {}
      this.dialog = true
      if (this.$refs.formData) {
        this.$refs.formData.resetValidation()
      }
    },
    showEdit(data) {
      this.type = TYPE_UPDATE
      this.form = { ...data }
      this.dialog = true
    },
    async updateMe() {
      try {
        await checkToken()
        let result = await checkToken()
        Store.commit('auth/SET_USER', result.data)
      } catch (error) {
        window.location.reload()
      }
    },
    async submitData() {
      try {
        this.$refs.formData.validate()
        if (!this.valid) return
        this.loading = true
        if (this.type === TYPE_CREATE) {
          await createOrganization(this.form)
          await this.updateMe()
        }
        if (this.type === TYPE_UPDATE) {
          await updateOrganization(this.form.uuid, this.form)
        }
        this.dialog = false
        if (this.$parent.getOrganization) {
          this.$parent.getOrganization()
        }
        if (this.$parent.getListUsers) {
          this.$parent.getListUsers()
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
